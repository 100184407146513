.LandingLogo svg{
    width: 416px;
    height: auto;
}

@media screen and (max-width: 400px)
{
    .LandingLogo svg{
        width: 360px;
    }
}

@media screen and (max-width: 320px)
{
    .LandingLogo svg{
        width: 310px;
    }
}