body
{
    background-color:        white;
    margin:                  0;
    font-family:             -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                             'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                             sans-serif;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code
{
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
                 monospace;
}

@media screen and (max-device-width: 480px)
{
    body
    {
        -webkit-text-size-adjust: none;
    }
}